<template>
  <div class="root">
    <el-row class="header-block">
      <el-col :span="20" class="search-filter-root">
        <div class="grid-content">
          <el-form @submit.native.prevent>
            <div class="el-input">
              <input
                id="searchInput"
                placeholder="Try a location or club name"
                v-model="searchInput"
                class="el-input__inner rounded"
                style="padding-left: 16px"
              />
              <span class="el-input__suffix">
                <span class="el-input__suffix-inner">
                  <button class="icon-wrapper search-button" @click.prevent="fetchSearchData">
                    <i class="icon-search"></i>
                  </button>
                </span>
              </span>
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4" class="search-filter-root">
        <el-col :span="24">
          <div class="list" @click="toggleMap(!mapVisible)">
            <i v-if="!mapVisible" class="icon-map" />
            <i v-if="mapVisible" class="icon-list" />
          </div>
        </el-col>
      </el-col>
    </el-row>
    <el-row>
      <div v-show="!mapVisible && competitions.length > 0">
        <el-col class="content card-list-root">
          <p class="sort-text">Sorted by closest to search location</p>
          <!-- important: area for search results   -->
          <div v-if="competitions.length === 0" class="no-results">
            No results found. Please try with different location/name or update filters.
          </div>
          <CompetitionCard
            :id="`location_${object._id}`"
            v-for="(object, i) in competitions"
            :object="object"
            :key="`${object._id}+${i}`"
            :ref="`location-${object._id}`"
            :locality="`${competitions.locality}`"
            @submit="submit(object)"
            class="card"
          />
        </el-col>
      </div>
      <el-col v-show="mapVisible">
        <Map
          :renderContent="competitions"
          @updateSearchInput="updateSearchInput"
          @scrollToCard="scrollToCard"
          :isMobile="true"
        />
      </el-col>
    </el-row>
    <div class="padding-lr-10-sm-and-up cant-find-card m-1">
      <el-card class="mt-2 d-flex-column flex-0 align-text-center" v-if="cantFind">
        <div class="flex-1 align-text-center m-1">Can't find what you are looking for?</div>
        <el-button
          @click="handleCantFind"
          type="success"
          class="full-width button-light button-thin mw-300"
          >Get in contact</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/fp/map";
import { mapActions, mapGetters } from "vuex";
import CompetitionCard from '@/components/card/CompetitionCardBeta.vue';
import msg from "@/utils/constants/msg";
import Map from "@/components/map/Map.vue";

export default {
  name: 'CompSearch',
  components: {
    CompetitionCard,
    Map
  },
  props: {
    criteria: String,
    source: String,
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters('registrationTeams', ['current']),
  },
  methods: {
    ...mapActions("registration", ["updateCurrent"]),
    updateSearchInput(data) {
      this.searchInput = data;
      this.fetchSearchData();
    },

    toggleMap(to) {
      this.mapVisible = to;
    },

    handleUpdateFilter(tag) {
      // remove tag from this.appliedFiltersReal.participantTypes
      const newParticipantTypes = this.appliedFiltersReal.participantTypes.filter(
        (item) => item !== tag
      );
      this.appliedFiltersReal.participantTypes = newParticipantTypes;
    },

    scrollToCard(location) {
      const card = this.$refs[`location-${location._id}`][0];
      card.$el.scrollIntoView({ behavior: "smooth" });
    },

    cloneDeep,
    handleCantFind() {
      // Expression of Interest for National Bodies
      this.$router
        .push({
          name: "expressinterest",
          params: { entityType: "national", entityId: 1 },
        })
        .catch(() => {});
    },
    regoOpen(object) {
      if (object.maxNoTeams) { return (object.maxNoTeams - object.noTeams > 0 && object.regoOpen); }
      return object.regoOpen;
    },
    submit(object) {
      this.updateCurrent({ entity: object });
      if (this.regoOpen(object) && this.isAuthenticated) {
        this.$router.push({ name: 'teamform' });
      } else if (this.regoOpen(object)) {
        this.$router.push({ name: 'loginrequired', params: { loginRedirect: '/register/teamform' } });
      } else {
        // Expression of Interest for Entities
        this.$router.push({
          name: 'expressinterest',
          params: { entityType: object.entityType, entityId: object._id },
        });
      }
    },
    fetchSearchData() {
      this.cantFind = true;

      const criteria = this.searchInput.split(',')[0];

      this.$store.commit('root/LOADING', true);

      this.$httptoken
        .post('/nrl/api/v1/portal-public/registration-team/search', { criteria })
        .then((response) => {
          const result = response.data;
          if (result && result.data && result.data.length !== 0) {
            this.competitions = result.data.map(c => Object.assign({}, c, {
              entityType: 'competition',
              contact: c.association.contact,
            }));
          } else {
            this.competitions = [];
          }
          this.$store.commit('root/LOADING', false);
        })
        .catch(() => {
          this.$store.commit(
            'views/PUSH_NOTIFICATION',
            {
              msg: msg.error.apiError,
              type: 'warning',
            },
            { root: true },
          );
          this.competitions = [];
          this.$store.commit('root/LOADING', false);
        });
    },
  },
  mounted() {
    if (!this.current.type) {
      this.updateCurrent({ type: 'team' });
      this.$store.dispatch('registration/clearCurrent');
    }
    if (this.source) {
      // 'rugby-league' or 'touch-football'
      this.$store.dispatch('views/updateNationalOrg', this.source);
    }
    if (this.criteria) {
      this.searchInput = this.criteria;
      this.fetchSearchData();
    }
  },
  data() {
    return {
      cantFind: false, // After search has been conducted
      // Search
      searchInput: '',
      searchTimeout: null,
      competitions: [],
      map: undefined,
      markers: [],
      infoWindow: undefined,
      currentLocation: undefined,
      activeTab: 0,
      competitions: [],
      mapVisible: true,
    };
  },
};
</script>

<style lang="scss">
.text-days::before {
  content: var(--content);
  color: var(--color);
  text-transform: uppercase;
  position: absolute;
  font-family: $fontRegular;
  font-size: 0.875rem;
  top: 10px;
  left: 15px;
  size: 0;
  z-index: 1;
}
</style>

<style lang="scss" scoped>
.append-icon-close {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  color: $secondary;
}

.has-value {
  // make it a cross
  ::v-deep i.icon-arrow-down {
    background: none;
  }

  i.icon-arrow-down {
    background: none;
  }

  ::v-deep .el-input__inner {
    height: calc(0.875rem + 26px);
    border-color: $secondary;
    color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }

  .el-input__inner {
    border-color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }
}

::v-deep .el-select {
  height: calc(0.875rem + 26px);
}

.el-input {
  .el-input__suffix {
    position: absolute;
    right: 8px;
    transition: 0.5s;
  }
  &.visible {
    .el-input__suffix {
      transform: rotate(-180deg);
    }
  }
}

.content {
  // height: 90vh;
  overflow: auto;
}

.select-1 {
  position: relative;
  width: 100%;
  @media (min-width: $sm) {
    max-width: 250px;
  }
}

.select-2 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.select-3 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.filters {
  padding-left: 10px;
  flex-wrap: wrap;
}

.min-1000 {
  min-height: 1400px;
}

::v-deep .el-input-group__append {
  padding: 0;
  border: 0;
}

.icon-wrapper {
  height: 40px;
  width: 55px;
}

.mw-50p {
  width: 100%;
  @media (min-width: 1263px) {
    max-width: 50%;
  }
}

.header-block {
  box-sizing: border-box;
  background: #ffffff;
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  padding-left: 1em;
  padding-bottom: 1em;
}
.header-text {
  font-weight: 800;
  font-size: 20px;
  color: #222222;
  padding-top: 1em;
  // margin-bottom: 0;
}
.search-filter-root {
  padding-top: 2em;
}
.cant-find-card {
  margin-bottom: 2em;
}
.card-list-root {
  background: #f6f6f6;
  backdrop-filter: blur(4px);
  padding-top: 0.8em;
  padding-left: 1.6em;
  padding-right: 1.6em;
}
.sort-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.tabs {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-nav {
  display: flex;
}

.tab {
  cursor: pointer;
  margin-right: 10px;
  background: #e6e6e6;
  border-radius: 100px;
  // min-width: 36px;
  padding-right: 1em;
  padding-left: 1em;
  font-size: 0.8em;
  line-height: 2.4em;
}

.tab.active {
  background: #00db00;
}

.tab-content {
  margin-top: 10px;
}
.grid-content {
  min-height: 36px;
}

.list {
  width: 40px;
  height: 40px;
  z-index: 999;
  margin-left: 8px;
  border-radius: 40px;
  background-color: #f6f6f6;
  // flex align center
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e6e6e6;
  }
  // closed menu hamburger
  .icon-list {
    display: inline-block;
    background-image: url("../../../assets/icons/list.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 10px;
  }
  .icon-map {
    display: inline-block;
    background-image: url("../../../assets/icons/map.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 10px;
  }
}

.filter {
  width: 40px;
  height: 40px;
  z-index: 999;
  border-radius: 40px;
  background-color: #f6f6f6;
  margin-left: 16px;
  // flex align center
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e6e6e6;
  }
  // closed menu hamburger
  .icon-filter {
    display: inline-block;
    background-image: url("../../../assets/icons/filter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 10px;
  }
}
.el-tag {
  margin-top: 1em;
  margin-right: 0.8em;
  color: "#000";
}
.no-results {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
.rounded {
  border-radius: 8px !important;
}
.search-button {
  background: none;
  margin-right: -12px;
  // padding: 0.5em 1em;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1.2em;
  border: none;
  cursor: pointer;
}
.icon-search {
  background-image: url("~@/assets/icons/search-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.3125rem 1.25rem;
  padding: calc((1.25rem / 2) + 2px);
}
</style>
