<template>
  <div>
    <a :href="mapsLink" target="_blank">
      <p class="m-0" v-for="(line, index) in addressArray" :key="index">
        {{ line }}
      </p>
    </a>
  </div>
</template>
<script>
import compose from 'lodash/fp/compose';
import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import get from 'lodash/get';

export default {
  name: 'AddressArray',
  props: {
    object: Object,
  },
  computed: {
    addressArray() {
      const { address } = this.object.contact && this.object.contact.address ? this.object.contact : this.object.venue;
      return compose(
        filter(line => line.toLowerCase() !== 'undefined'),
        concat(get(address, 'addressLine1', '').split(',')),
        concat(get(address, 'addressLine2', '').split(',')),
        concat([
          `${get(address, 'suburb', '')} ${get(address, 'state', '')} ${get(address, 'postcode', '')}`, `${get(address, 'country', '')}`,
        ]),
      )([]);
    },
    mapsLink() {
      return `http://maps.google.com/maps?q=${this.addressArray.join(' ')}`;
    },
  },
};
</script>
