<template>
  <div class="d-flex mw-700 flex-center">
    <div class="lt-sm-12 gt-sm-6 mt-1">
      <el-button id="cardButton" type="primary" @click="submit" class="button full-width">
        {{ submitButtonText }}
      </el-button>
    </div>
    <div class="lt-sm-12 gt-sm-6 mt-1" v-if="object.more">
      <el-button type="success" class="button full-width"> Find out more </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardButtonGroup",
  props: {
    object: {},
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
  computed: {
    submitButtonText() {
      if (
        (this.object.entityType === "club" ||
          this.object.entityType === "competition" ||
          this.object.entityType === "association" ||
          this.object.entityType === "team") &&
        this.expressInterest
      )
        return "Get In Contact";
      return "Register";
    },
    expressInterest() {
      if (this.object.maxNoTeams) {
        return !(this.object.maxNoTeams - this.object.noTeams > 0 && this.object.regoOpen);
      }
      if (this.object.teamInManyComps) return true;
      return !this.object.regoOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  margin-left: 0;
}

.d-flex {
  flex-wrap: wrap;
}
</style>
