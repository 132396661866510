<template>
  <div>
    <component
      :is="isMobile ? 'MobileComponent' : 'WebComponent'"
      :criteria="criteria"
      :type="type"
      :activity="activity"
      :gender="gender"
      :agemin="agemin"
      :agemax="agemax"
      :comptype="comptype"
      :source="source"
      :entity="entity"
      :entitytype="entitytype"
    />
  </div>
</template>

<script>
import WebComponent from "./WebView.vue";
import MobileComponent from "./MobileView.vue";

export default {
  components: {
    WebComponent,
    MobileComponent,
  },
  props: {
    criteria: String,
    type: Array,
    activity: Array,
    gender: String,
    agemin: Number,
    agemax: Number,
    comptype: Array,
    source: String,
    entity: Boolean,
    entitytype: String,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
  },
};
</script>
